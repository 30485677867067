import { ReactElement } from "react";
import { format as formatDate, formatISO, isValid, parseISO } from "date-fns";
import { upperFirst } from "lodash";

type NumberFormatterValue = string | number | null | undefined;
type NumberFormatterReturn = string | undefined;
export type NumberFormatterFunction = (
  value: NumberFormatterValue,
) => NumberFormatterReturn;

export type FormatCurrencyOptions = Intl.NumberFormatOptions & {
  preserveDecimals?: boolean;
};

export function formatISODate(date: Date): string;
export function formatISODate(
  date: string | Date | null | undefined,
): string | undefined;
export function formatISODate(
  date: string | Date | null | undefined,
): string | undefined {
  if (!date) return;

  if (typeof date === "string") {
    return formatISODate(parseISO(date));
  }

  if (!isValid(date)) return;

  return formatISO(date, { representation: "date" });
}

export function makeFormatDate(format: string) {
  return (date: string | Date | null | undefined): string | undefined => {
    if (!date) return;

    if (typeof date === "string") {
      return makeFormatDate(format)(parseISO(date));
    }

    if (!isValid(date)) return;

    return formatDate(date, format);
  };
}

export const formatFullDate = makeFormatDate("PPP");

export const ZERO_DASH = "–";

type ZeroFormatterFunction<T> = (formatted: NumberFormatterReturn) => T;

export function makeFormatZero<T extends string | null | ReactElement>(
  zeroFormatter: ZeroFormatterFunction<T> = () => ZERO_DASH as T,
) {
  return function formatZero(formatter: NumberFormatterFunction) {
    return (value: NumberFormatterValue) => {
      const formatted = formatter(value);
      if (
        value === 0 ||
        value === "0" ||
        (typeof value === "string" && /^0(?:\.0+)?$/.test(value))
      ) {
        return zeroFormatter(formatted);
      }
      return formatted;
    };
  };
}

export function makeFormatDecimal(
  options?: Intl.NumberFormatOptions,
  strParser: (str: string) => number = (s) => parseFloat(s),
) {
  return (value: NumberFormatterValue): NumberFormatterReturn => {
    if (!value && value !== 0) return;

    if (typeof value === "string") {
      return makeFormatDecimal(options)(strParser(value));
    }

    return value.toLocaleString("en-US", {
      style: "decimal",
      ...options,
    });
  };
}

export const formatDecimal = makeFormatDecimal();

export function makeFormatInteger() {
  return makeFormatDecimal({ maximumFractionDigits: 0 }, (s) =>
    parseInt(s, 10),
  );
}

export const formatInteger = makeFormatInteger();

export function makeFormatCurrency({
  preserveDecimals = false,
  ...rest
}: FormatCurrencyOptions = {}) {
  return (value: NumberFormatterValue): NumberFormatterReturn => {
    if (!value && value !== 0) return;

    if (typeof value === "string") {
      const normalized = value.startsWith(".") ? `0${value}` : value;

      const parsed = parseFloat(normalized);

      if (!preserveDecimals) return makeFormatCurrency(rest)(parsed);

      const [, decimals] = normalized.split(".");

      if (!decimals) return makeFormatCurrency(rest)(parsed);

      return makeFormatCurrency({
        ...rest,
        minimumFractionDigits: Math.min(decimals.length, 2),
      })(parsed);
    }

    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...rest,
    });
  };
}

export const formatCurrency = makeFormatCurrency();

export type NumberRangeFormatterValue =
  | {
      start?: { value: NumberFormatterValue } | null;
      end?: { value: NumberFormatterValue } | null;
    }
  | null
  | undefined;

export function makeFormatCurrencyRange(
  currencyFormatter: ReturnType<typeof makeFormatCurrency>,
) {
  return (value: NumberRangeFormatterValue) => {
    const startValue = value?.start?.value;
    const endValue = value?.end?.value;

    if (startValue === endValue) return currencyFormatter(startValue);

    return `${currencyFormatter(startValue) ?? ""}–${
      currencyFormatter(endValue) ?? ""
    }`;
  };
}

export function makeFormatPercent(options?: Intl.NumberFormatOptions) {
  return (value: NumberFormatterValue): NumberFormatterReturn => {
    if (!value && value !== 0) return;

    if (typeof value === "string") {
      return makeFormatPercent(options)(parseFloat(value));
    }

    return value.toLocaleString("en-US", {
      style: "percent",
      ...options,
    });
  };
}

export const formatPercent = makeFormatPercent();

type GetPercentValueOptions = {
  /** Whether to convert from a whole percent style number to a decimal style
   * number, i.e. divide by 100 instead of multiplying by 100 (the default). */
  isToDecimal?: boolean;
};

export function getPercentValue(
  value: number,
  options?: GetPercentValueOptions,
): number;
export function getPercentValue(
  value: number | undefined,
  options?: GetPercentValueOptions,
): number | undefined;
export function getPercentValue(
  value: string | number | null,
  options?: GetPercentValueOptions,
): string | number | null;
export function getPercentValue(
  value: string | number | null | undefined,
  options?: GetPercentValueOptions,
): string | number | null | undefined;
export function getPercentValue(
  value: string | number | null | undefined,
  options: GetPercentValueOptions = {},
): string | number | null | undefined {
  const { isToDecimal } = options;
  if (!value) return value;
  if (typeof value === "string") {
    const pctValue = getPercentValue(parseFloat(value), options);
    return pctValue || pctValue === 0 ? String(pctValue) : pctValue;
  }
  const newValue = value * (isToDecimal ? 1 / 100 : 100);
  // Floating point numbers can result in erroneous calculations, e.g. 14.00000000001
  const rounded = Math.round(newValue * 10000) / 10000;
  return rounded;
}

const UNITS = ["thousands", "millions", "billions"] as const;
type Units = typeof UNITS[number];

const suffixByUnit: Record<Units, string> = {
  thousands: "k",
  millions: "M",
  billions: "B",
};

const divisorByUnit: Record<Units, number> = {
  thousands: 1e3,
  millions: 1e6,
  billions: 1e9,
};

export function makeFormatUnits(
  formatter: NumberFormatterFunction,
  units:
    | Units
    | null
    | undefined
    | false
    | ((value: number) => Units | null | undefined | false),
): NumberFormatterFunction {
  if (!units) return formatter;
  return (value) => {
    if (!value) return formatter(value);

    const floatValue = typeof value === "string" ? parseFloat(value) : value;

    const resolvedUnits =
      typeof units === "function" ? units(floatValue) : units;

    if (!resolvedUnits) return formatter(value);

    const divisor = divisorByUnit[resolvedUnits];
    const suffix = suffixByUnit[resolvedUnits];

    const unitValue = floatValue / divisor;

    const formatted = formatter(unitValue);

    if (!formatted) return formatted;

    return `${formatted}${suffix}`;
  };
}

const divisors: number[] = Object.values(divisorByUnit);
const divisorsSortedDesc = divisors.sort().reverse();

/**
 * Given a value, automatically returns the largest possible unit type that
 * keeps the value whole, e.g.
 *
 * 1000+ thousands
 * 1000000+ millions
 * etc
 */
export function getAutoUnitsForValue(
  value: NumberFormatterValue,
): Units | undefined {
  if (!value) return;

  if (typeof value === "string") return getAutoUnitsForValue(parseFloat(value));

  const divisor = divisorsSortedDesc.find((d) => value / d >= 1);

  if (!divisor) return;

  return UNITS.find((unit) => divisorByUnit[unit] === divisor);
}

const FILESIZE_UNITS = [
  "byte",
  "kilobyte",
  "megabyte",
  "gigabyte",
  "terabyte",
  "petabyte",
] as const;

export function getCompactFilesizeUnit(filesize: number) {
  return FILESIZE_UNITS.find((unit, i) => {
    if (i === FILESIZE_UNITS.length - 1) return true;
    const nextBytes = 1e3 ** (i + 1);
    return filesize / nextBytes < 1;
  });
}

export function makeFormatFilesize(options?: Intl.NumberFormatOptions) {
  return function formatFilesize(filesize: number | null | undefined) {
    if (!filesize && filesize !== 0) return;

    const unit = getCompactFilesizeUnit(filesize);

    if (!unit) return makeFormatInteger()(filesize);

    const compactedFilesize =
      filesize / (1e3 ** FILESIZE_UNITS.indexOf(unit) || 1);

    return compactedFilesize.toLocaleString(undefined, {
      style: "unit",
      unit,
      unitDisplay: "short",
      maximumFractionDigits: 1,
      ...options,
    });
  };
}

export const formatFilesize = makeFormatFilesize();

export function defaultToEmptyString<TValue>(
  formatter: (value: TValue) => NumberFormatterReturn,
): (value: TValue) => string {
  return (value) => formatter(value) || "";
}

export function titleCase(text: string) {
  return text
    .split(/(\W)/)
    .map((w) => upperFirst(w))
    .join("");
}

type ConjunctionJoinOptions = {
  finalConjunction?: string;
  isOxford?: boolean;
  separator?: string;
};

export function conjunctionJoin(
  texts: string[] | null | undefined,
  {
    finalConjunction = " and ",
    isOxford = true,
    separator = ", ",
  }: ConjunctionJoinOptions = {},
) {
  if (!texts?.length) return "";

  if (texts?.length === 1) return texts[0];

  return [texts.slice(0, -1).join(separator), texts.slice(-1)].join(
    `${
      isOxford && texts.length > 2 ? separator.trimEnd() : ""
    }${finalConjunction}`,
  );
}

export function formatUrlAsDomain(
  url: string | null | undefined,
): string | null | undefined {
  return url?.replace(/(?:https?:\/\/)?(?:www\.)?([^/$]+)(?:\/.*)?$/, "$1");
}
