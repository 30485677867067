import { EnumObj } from "utils/schemas/utils";

export class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, AssertionError.prototype);
  }
}

export function assertPresence<V>(
  variable: V | null | undefined,
  message = "Received a falsy value for a required variable!",
): asserts variable is V {
  if (!variable && variable !== 0 && variable !== false)
    throw new AssertionError(message);
}

export function assertBrandId(
  brandId: UUID | null | undefined,
): asserts brandId is UUID {
  return assertPresence(brandId, "Brand ID required but unavailable!");
}

export function makeGetAssertedType<T>(
  assertionFn: (value: unknown) => asserts value is T,
) {
  return function getAssertedType(value: unknown) {
    assertionFn(value);
    return value;
  };
}

export function makeAssertIsEnum<E>(enumObject: EnumObj<E>) {
  return function assertIsEnum(value: unknown): asserts value is E {
    if (!Object.values(enumObject).includes(value)) {
      throw new AssertionError("Expected a valid enum value");
    }
  };
}

export function assertIsArray(value: unknown): asserts value is unknown[] {
  if (!Array.isArray(value)) {
    throw new AssertionError("Expected an array");
  }
}

export function getAssertedArray(value: unknown): unknown[] {
  assertIsArray(value);
  return value;
}

export function getAssertedArrayOfType<R>(
  value: unknown,
  assertionFunction: (value: unknown) => asserts value is R,
): R[] {
  return getAssertedArray(value).map((v) => {
    assertionFunction(v);
    return v;
  });
}

export function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== "string") {
    throw new AssertionError("Expected a string");
  }
}

export const getAssertedString = makeGetAssertedType(assertIsString);

export function assertIsBoolean(value: unknown): asserts value is boolean {
  if (typeof value !== "boolean") {
    throw new AssertionError("Expected a boolean");
  }
}

export const getAssertedBoolean = makeGetAssertedType(assertIsBoolean);

export function assertIsNumber(value: unknown): asserts value is number {
  if (typeof value !== "number") {
    throw new AssertionError("Expected a number");
  }
}

export const getAssertedNumber = makeGetAssertedType(assertIsNumber);

export function getAssertedStringArray(value: unknown): string[] {
  return getAssertedArrayOfType(value, assertIsString);
}
